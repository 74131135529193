import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalSettingsService {
  private formValuesSubject = new BehaviorSubject<any>(null); // Default value can be null or an initial form state
  formValues$ = this.formValuesSubject.asObservable();

  updateFormValues(values: any) {
    this.formValuesSubject.next(values);
  }
  constructor() { }
}
