import { Component } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Auth } from "@angular/fire/auth";
import { OrganizationInvitationsService } from "../../../../services/organization-invitations.service";
import { OrganizationsApi } from "../../../../api/organizations.api";

@Component({
  selector: "company-form",
  templateUrl: "./company-form.component.html",
  styleUrls: ["./company-form.component.css"],
})
export class CompanyFormComponent {
  isLoading: boolean = false;
  companyName: string | undefined;
  constructor(
    private dialogRef: MatDialogRef<CompanyFormComponent>,
    private auth: Auth,
    private organizationService: OrganizationsApi,
  ) {}

  createCompany() {
    if (!this.companyName) {
      return;
    }

    this.isLoading = true;

    this.organizationService
      .create(this.auth.currentUser?.uid!, this.companyName)
      .then((response) => {
        this.dialogRef.close();
      })
      .catch((error) => {
        console.error(error);
        this.isLoading = false;
      });
  }
}
