import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ILogoFile } from '../../../../../../../model/IOrganization';

@Component({
  selector: 'file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.css']
})
export class FilePickerComponent {
  @Input() fileControl!: FormControl;
  @Output() fileChange = new EventEmitter<File | null>();
  file: File | ILogoFile | null = null;

  constructor() {
    this.file = this.fileControl?.value ?? null;
  }

  onFileSelected(event: Event) {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    if (!files[0]) return;

    if (this.isFileTypeAllowed(files[0])) {
      this.addFile(files[0]);
      this.fileChange.emit(files[0]);
    }
  }

  isFileTypeAllowed(file: File) {
    const fileType = file.type;
    return fileType === 'image/png' || fileType === 'image/jpeg' || fileType === 'image/svg';
  }

  addFile(file: File) {
    this.fileControl.setValue(file);
    this.file = file;
  }

  removeFile(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileControl.reset();
    this.file = null;
    this.fileChange.emit(null);
  }

  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    const files = event.dataTransfer!.files;
    if (!files[0]) return;
    if (this.isFileTypeAllowed(files[0])) {
      this.addFile(files[0]);
    }
  }

  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
  }
}
