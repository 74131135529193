import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { OrganizationsApi } from '../../../api/organizations.api';
import { StoreService } from '../../../services/store.service';
import { Observable } from 'rxjs';
import {
  ILogoFile,
  IOrganization,
  IOrganizationContact,
  IOrganizationDetails,
  IPortalConfiguration
} from '../../../../../../model/IOrganization';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DeepPartial } from 'chart.js/dist/types/utils';
import { StorageApi } from '../../../api/storage.api';
import { PortalSettingsService } from '../../../services/portal-settings.service';

@Component({
  selector: 'settings-portal',
  templateUrl: './settings-portal.component.html',
  styleUrls: ['./settings-portal.component.css', '../settings.component.css']
})
export class SettingsPortalComponent implements OnInit {
  organization$: Observable<IOrganization | undefined>;
  showSubmitButton: boolean = false;
  companyForm: FormGroup  = this.fb.group({
    portalEndpoint: ['', Validators.required],
    companyInfo: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    website: ['', Validators.required],
    phone: [''],
    file: ['', Validators.required],
    cvDropOff: ['', Validators.required],
  });

  portalDomain: string = 'portal.hirefox.ai';
  isPortalEnabled = new FormControl();
  private initialFormValues: any;

  constructor(
    public dialog: MatDialog,
    private organizationService: OrganizationsApi,
    private store: StoreService,
    private fb: FormBuilder,
    private storageApi: StorageApi,
    private portalSettingsService: PortalSettingsService,
  ) {

    this.organization$ = this.organizationService.getOne(this.store.organizationId!);
  }

  ngOnInit(): void {
    this.organization$.subscribe((organization) => {
      console.log(organization);
      this.initializeForm(organization);
    });

    this.companyForm.valueChanges.subscribe((values) => {
      this.portalSettingsService.updateFormValues(values);

      this.showSubmitButton = this.showSubmitButton =
        JSON.stringify(this.initialFormValues) !== JSON.stringify(this.companyForm.getRawValue());
    });

    this.portalEndpointControl?.valueChanges.subscribe((value) => {
      const lowercaseValue = value.toLowerCase();
      this.portalEndpointControl?.setValue(lowercaseValue, { emitEvent: false });
    });

    // this.isPortalEnabled?.valueChanges.subscribe((value) => {
    //   console.log(value);
    //   // this.organizationService.togglePortal(this.store.organizationId!, value);
    // });

    // this.portalEndpoint?.valueChanges?.pipe(debounceTime(400), distinctUntilChanged()).subscribe((params) => {
    //   console.log(params);
    //   this.organizationService.updatePortalEndpoint(this.store.organizationId!, params);
    // });
  }

  private initializeForm(organization: IOrganization | undefined) {
    this.portalEndpointControl!.setValue(organization?.portalConfiguration?.portalEndpoint ?? '');
    this.companyInfoControl!.setValue(organization?.portalConfiguration?.organizationInfo?.companyInfo ?? '');
    this.emailControl!.setValue(organization?.portalConfiguration?.organizationInfo?.contact?.email ?? '');
    this.websiteControl!.setValue(organization?.portalConfiguration?.organizationInfo?.contact?.website ?? '');
    this.phoneControl!.setValue(organization?.portalConfiguration?.organizationInfo?.contact?.phone ?? '');
    this.fileControl!.setValue(organization?.portalConfiguration?.file ?? '');
    this.cvDropOffControl!.setValue(organization?.portalConfiguration?.cvDropOff ?? false);

    this.isPortalEnabled.setValue(organization?.isPortalEnabled ?? false);

    this.initialFormValues = this.companyForm.getRawValue();

    this.showSubmitButton = this.showSubmitButton =
      JSON.stringify(this.initialFormValues) !== JSON.stringify(this.companyForm.getRawValue());
  }

  get portalEndpointControl() {
    return this.companyForm.get('portalEndpoint');
  }
  get emailControl() {
    return this.companyForm.get('email');
  }
  get cvDropOffControl() {
    return this.companyForm.get('cvDropOff') as FormControl;
  }
  get phoneControl() {
    return this.companyForm.get('phone');
  }
  get websiteControl() {
    return this.companyForm.get('website');
  }
  get companyInfoControl() {
    return this.companyForm.get('companyInfo');
  }
  get fileControl() {
    return this.companyForm.get('file') as FormControl;
  }

  async onSubmit() {
    if (this.companyForm.invalid) return;

    const changedValues = this.getChangedValues();

    // if the file has the property name that means it's a new file
    // if not it will have the property originalName and that means that the file doesn't need changes
    if (this.fileControl.value.name) {
      const uploadedLogo = await this.storageApi.uploadPortalLogo(this.fileControl.value.name, this.fileControl.value);
    }

    const dto = await this.makeDto();
    console.log(dto);

    const portalConfig = await this.organizationService.updatePortalConfiguration(this.store.organizationId!, dto);

  }

  getChangedValues(): any {
    const currentValues = this.companyForm.getRawValue();
    const changedValues: any = {};

    Object.keys(currentValues).forEach((key) => {
      if (currentValues[key] !== this.initialFormValues[key]) {
        changedValues[key] = currentValues[key];
      }
    });

    return changedValues;
  }

  onPortalEndpointChange($event: Event) {
    //   console.log(this.portalEndpoint?.value);
  }

  private async makeDto() {
    const formValues = this.companyForm.getRawValue();
    const contact: Partial<IOrganizationContact> = {
      email: formValues.email,
      phone: formValues.phone,
      website: formValues.website
    };
    const organizationDetails: DeepPartial<IOrganizationDetails> = {
      companyInfo: formValues.companyInfo,
      contact: contact
    };

    let file: Partial<ILogoFile> = {};
    // if the file has the property name that means it's a new file
    // if not it will have the property originalName and that means that the file doesn't need changes
    if (this.fileControl.value.name) {
      const filePath = `${this.storageApi.getPortalLogoStoragePath()}${this.fileControl.value.name}`;
      const fileUrl = await this.storageApi.getFileUrl(filePath);
      file.type = this.fileControl.value.type;
      file.size = this.fileControl.value.size;
      file.originalName = this.fileControl.value.originalName;
      file.path = filePath;
      file.url = fileUrl;
    }else{
      file = this.fileControl.value;
    }

    const portalConfiguration: DeepPartial<IPortalConfiguration> = {
      portalEndpoint: formValues.portalEndpoint,
      organizationInfo: organizationDetails,
      file: file,
      cvDropOff: formValues.cvDropOff,
    };

    return portalConfiguration;
  }

  // private makeDto(changedValues: any) {
  //   const dto: any = {};
  //   console.log(changedValues);
  //   if (changedValues.email) {
  //     dto['portalConfiguration.organizationInfo.contact.email'] = changedValues.email;
  //   }
  //   if (changedValues.phone) {
  //     dto['portalConfiguration.organizationInfo.contact.phone'] = changedValues.phone;
  //   }
  //   if (changedValues.website) {
  //     dto['portalConfiguration.organizationInfo.contact.website'] = changedValues.website;
  //   }
  //   if (changedValues.companyInfo) {
  //     dto['portalConfiguration.organizationInfo.companyInfo'] = changedValues.companyInfo;
  //   }
  //   if (changedValues.portalEndpoint) {
  //     dto['portalConfiguration.portalEndpoint'] = changedValues.portalEndpoint;
  //   }
  //   return dto;
  // }

  async uploadLogo() {
    console.log(this.fileControl.value);
    // await this.storageApi.uploadPortalLogo(this.file.value.name, this.file.value);
  }

  canSubmit() {
    return this.showSubmitButton && this.companyForm.valid;
  }

  togglePortal($event: Event) {
    this.organizationService.togglePortal(this.store.organizationId!, this.isPortalEnabled.value);
  }

  onFileChange($event: File | null) {}
}
