<ng-container>
  <label for="fileUpload">
    <div class="dropzone column center" [ngClass]="{ 'error-border': fileControl.errors && fileControl.touched }" *ngIf="fileControl?.invalid">
      <div class="dropzone-icon"><img src="assets/images/upload.svg" alt="upload" /></div>
      <div class="dropzone-title"><b>Upload your logo &nbsp;</b> Or Drag & Drop it here.</div>
      <div class="dropzone-title"><small> Accepted files: JPEG, PNG, SVG. </small></div>
    </div>
    <div class="dropzone column" *ngIf="!fileControl?.invalid">
      <div class="dropzone-uploads fill-space column">
        <div class="dropzone-file">
          <div class="dropzone-file-icon center"><img src="assets/images/file-gray.svg" alt="uploaded file" /></div>
          <div class="dropzone-file-name fill-space column column-center-left">{{ fileControl.value.name || fileControl.value.originalName }}</div>
          <div class="dropzone-file-delete center" (click)="removeFile($event)">
            <img src="assets/images/delete.svg" alt="remove file" />
          </div>
        </div>
      </div>
    </div>
  </label>
  <input id="fileUpload" type="file" #uploadElement (change)="onFileSelected($event)" accept=".jpg,.png,.svg" />
  <div class="error-container form-error">
    <div *ngIf="fileControl.errors && fileControl.touched">
      <small *ngIf="fileControl.errors['required']">File is required</small>
    </div>
  </div>
</ng-container>
