import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IJob } from '../../../../../../../../model/IJob';
import { JobService } from '../../../../../services/job.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { EWorkArrangement } from '../../../../../../../../model/enums/EWorkArrangement';
import { EEmploymentType } from '../../../../../../../../model/enums/EEmploymentType';
import { EPayFrequency } from '../../../../../../../../model/enums/EPayFrequency';
import { ESeniorityLevel } from '../../../../../../../../model/enums/ESeniorityLevel';
import currencies from '../../../../../../assets/currencies.json';

@Component({
  selector: 'job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  job$: Observable<IJob | undefined> | undefined;
  publicListing = new FormControl();

  workArrangements = Object.values(EWorkArrangement);
  employmentTypes = Object.values(EEmploymentType);
  payFrequencies = Object.values(EPayFrequency);
  seniorityLevels = Object.values(ESeniorityLevel);
  currencies = currencies;

  jobForm: FormGroup = this.fb.group({
    title: ['', Validators.required],
    location: this.fb.group({
      country: ['', Validators.required],
      state: [''],
      city: ['']
    }),
    workArrangement: [null, Validators.required],
    employmentType: [null, Validators.required],
    salaryRange: this.fb.group({
      min: [0, [Validators.min(0)]],
      max: [0, [Validators.min(0)]],
      currency: [45, Validators.required],
      frequency: [null, Validators.required]
    }),
    department: [''],
    seniorityLevel: [null, Validators.required],
    description: ['', Validators.required]
  });

  initialFormValues: any;
  showSubmitButton: boolean = false;

  constructor(
    private jobService: JobService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.job$ = this.jobService.job$;
    this.job$?.subscribe((job) => {
      this.initializeForm(job);
    });

    this.jobForm.valueChanges.subscribe((values) => {

      this.showSubmitButton = this.showSubmitButton =
        JSON.stringify(this.initialFormValues) !== JSON.stringify(this.jobForm.getRawValue());
    });
  }

  get titleControl() {
    return this.jobForm.get('title');
  }
  get currencyControl() {
    return this.jobForm.get('salaryRange')?.get('currency');
  }
  get descriptionControl() {
    return this.jobForm.get('description') as FormControl;
  }
  get workArrangementControl() {
    return this.jobForm.get('workArrangement') as FormControl;
  }
  get employmentTypeControl() {
    return this.jobForm.get('employmentType') as FormControl;
  }
  get payFrequencyControl() {
    return this.jobForm.get('salaryRange')?.get('frequency') as FormControl;
  }
  get salaryMinControl() {
    return this.jobForm.get('salaryRange')?.get('min') as FormControl;
  }
  get salaryMaxControl() {
    return this.jobForm.get('salaryRange')?.get('max') as FormControl;
  }
  get salaryCurrencyControl() {
    return this.jobForm.get('salaryRange')?.get('currency') as FormControl;
  }
  get countryControl() {
    return this.jobForm.get('location')?.get('country') as FormControl;
  }
  get stateControl() {
    return this.jobForm.get('location')?.get('state') as FormControl;
  }
  get cityControl() {
    return this.jobForm.get('location')?.get('city') as FormControl;
  }
  get seniorityLevelControl() {
    return this.jobForm.get('seniorityLevel') as FormControl;
  }
  get departmentControl() {
    return this.jobForm.get('department') as FormControl;
  }

  onSubmit(): void {
    if (this.jobForm.invalid) {
      return;
    }
    console.log(this.jobForm.value);
  }

  togglePublicListing($event: Event) {}



  ngOnDestroy(): void {}

  private initializeForm(job: IJob | undefined) {
    this.titleControl!.setValue(job?.jobDetails?.title ?? '');
    this.descriptionControl!.setValue(job?.jobDetails?.description ?? '');
    this.workArrangementControl!.setValue(job?.jobDetails?.jobSetting ?? '');
    this.employmentTypeControl!.setValue(job?.jobDetails?.jobType ?? '');
    this.seniorityLevelControl!.setValue(job?.jobDetails?.seniorityLevel ?? '');
    this.salaryMinControl.setValue(job?.jobDetails?.salaryRange?.min ?? '');
    this.salaryMaxControl.setValue(job?.jobDetails?.salaryRange?.max ?? '');

    if (job?.jobDetails?.salaryRange?.currency) {
      const currency = this.currencies.findIndex((cur) => cur.code === job?.jobDetails?.salaryRange?.currency);
      this.salaryCurrencyControl.setValue(currency ?? '');
    }

    this.payFrequencyControl.setValue(job?.jobDetails?.salaryRange?.frequency ?? '');
    this.departmentControl.setValue(job?.jobDetails?.department ?? '');
    this.departmentControl.setValue(job?.jobDetails?.department ?? '');
    this.countryControl.setValue(job?.jobDetails?.location?.country ?? '');
    this.cityControl.setValue(job?.jobDetails?.location?.city ?? '');
    this.stateControl.setValue(job?.jobDetails?.location?.state ?? '');

    this.initialFormValues = this.jobForm.getRawValue();

    this.showSubmitButton = JSON.stringify(this.initialFormValues) !== JSON.stringify(this.jobForm.getRawValue());
  }

  canSubmit() {
    return this.showSubmitButton && this.jobForm.valid;
  }

}
